.App {
  text-align: center;  
  overflow: hidden;
  background-color: #190F0D;
  background-image: url('/background_1080.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.App-logo {
  width: 50vmin;
  margin: 6vh;
  pointer-events: none;
}

.App-content {
  margin-top: 2vh ;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  flex: 1;
}

.App-footer {
  margin-bottom: 1vh;
  display: flex;
  flex-direction: row ;
  justify-content: center;
  color: #d0d0d0 ;
}

.App-footer p{
  font-size: .8rem ;
  padding-left: 5px ;
  padding-right: 5px ;
}

.App-footer a{
  color: #d0d0d0 ;
  text-decoration: none ;
}

.App-link {
  color: #61dafb;
}